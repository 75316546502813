import React from "react";
import { connect } from "react-redux";
import { Anchor, IconArrowLeft24, Footer } from "acca-design-system";

import PefSideBar from "../sidebar/PefSidebar";
import DashBoardNav from "../DashBoardNav";
import ConstructedData from "./ConstructedData";
import Dashboardpagetitle from "../Dashboardpagetitle";

const ConstructedResponseDetails = (props) => {
  const conData = props.pef.singlePef.attributes[1];
  const cData = conData.values;
  const title = conData.displayName;
  const desc = conData.displayDesc;
  // console.log(cData)
  // const listIcon = () => {
  //   return;
  //   // eslint-disable-next-line no-unreachable
  //   <IconArrowRight24 />;
  // };

  return (
    <>
      <div className="column-layoutsplit detailpage-wrapper">
        <div className="">
          {/* onchangeclass={onchangeclassHandler} */}
          <PefSideBar />
        </div>

        <div className="dashboard-header-wrapper">
          <div className="content-wrapper">
            <DashBoardNav navBg={props.textColorChange} />
            <div className=" container-pef ">
              <div className="content-wrapper innerpage-title">
                <Dashboardpagetitle title={title} desc={desc} />
              </div>
            </div>
          </div>
        </div>
        <ConstructedData cData={cData} />

        <div className="bttn-links container-pef ">
          <Anchor
            className="u-font-bold "
            onClick={function noRefCheck() {}}
            to="/time-management"
          >
            <IconArrowLeft24 /> Objective test skills
          </Anchor>
        </div>
      </div>
      <Footer className="container-pef "></Footer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    pef: state.singlePef,
    textColorChange: state.textColorChange,
  };
};
export default connect(mapStateToProps)(ConstructedResponseDetails);
