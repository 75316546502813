import { Authorities, Scopes } from "@acca-digital/auth-service"
import { Configuration } from "@azure/msal-browser";

const tenantName: string = process.env.REACT_APP_TENANT_NAME;
const authorityBaseUrl = process.env.REACT_APP_AUTHORITY;

const authorities: Authorities = {
  signupOrSignin: `${authorityBaseUrl}/b2c_1a_static_acca_api`,
  signupOnly: `${authorityBaseUrl}/b2c_1a_static_acca_api`,
  signinOnly: `${authorityBaseUrl}/b2c_1a_static_acca_api`,
  forgotPassword: `${authorityBaseUrl}/b2c_1a_static_acca_api`,
  profileEdit: `${authorityBaseUrl}/b2c_1a_static_acca_api`,
};

const instanceConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    authority: authorities.signupOrSignin,
    knownAuthorities: [authorityBaseUrl],
    postLogoutRedirectUri: "/",
    redirectUri: process.env.REACT_APP_CALLBACK_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const scopes: Scopes = [
  "openid",
  "profile",
  "email",
  `https://${tenantName}.onmicrosoft.com/acca-api/api.READ`,
  "offline_access"
];

export default {
  authorities,
  instanceConfig,
  scopes,
};