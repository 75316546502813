import React, { useState } from "react";
import parse from "html-react-parser";
import Doughnutcircle from "../../Doughnut/Doughnut";

const ObjectiveData = (props) => {
  const [show, setShow] = useState(false);

  const dataToDisplayobj = props.curObjdata.map((curObjdata) => {
    const { code, displayName, mark } = curObjdata;
    //console.log(TmData.feedbacks);x
    return (
      <div className="accordiantab-wrapper " key={code}>
        <div className="accordian-tab ">
          <div className="container-pef ">
            <div className="row">
              <div
                className="col-1"
                style={{ display: show ? "display" : "none" }}
              ></div>
              <div className="col-2">
                <h4>{displayName}</h4>
              </div>
              <div className="column-reverseMobile">
                <div
                  className="col-3"
                  style={{ padding: show ? "padding" : "0px" }}
                >
                  {curObjdata.feedbacks.map((feedbacks) => {
                    return (
                      <div key={feedbacks.feedback}>
                        {parse(`${feedbacks.feedback}`)}
                      </div>
                    );
                  })}
                </div>
                <div className="col-4 no-marginTablet">
                  <Doughnutcircle marks={mark} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <>{dataToDisplayobj}</>;
};

export default ObjectiveData;
