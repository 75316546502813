import React from "react";
import {
  Anchor,
  IconArrowRight24,
  IconArrowLeft24,
  Footer,
} from "acca-design-system";
import { connect } from "react-redux";
import PefSideBar from "../sidebar/PefSidebar";
import DashBoardNav from "../DashBoardNav";
import Dashboardpagetitle from "../Dashboardpagetitle";
import ObjectiveData from "./ObjectiveData";

// import { textColorChange} from "./../../reducers/TextColor";

const ObjectiveDetails = (props) => {
  const objectiveList = props.objectivesinglePef.singlePef.attributes[3];
  const timeTitle = objectiveList.displayName;
  const timeDesc = objectiveList.displayDesc;

  return (
    <>
      <div className="column-layoutsplit detailpage-wrapper">
        <div className="">
          {/* onchangeclass={onchangeclassHandler} */}
          <PefSideBar />
        </div>

        <div className="dashboard-header-wrapper">
          <div className="content-wrapper">
            <DashBoardNav navBg={props.textColorChange} />
            <div className=" container-pef ">
              <div className="content-wrapper innerpage-title">
                <Dashboardpagetitle title={timeTitle} desc={timeDesc} />
              </div>
            </div>
          </div>
        </div>

        <ObjectiveData curObjdata={objectiveList.values} />

        <div className="bttn-links container-pef ">
          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/syllabus"
          >
            <IconArrowLeft24 /> Syllabus
          </Anchor>
          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/constructed-response"
          >
            Constructed response <IconArrowRight24 />
          </Anchor>
        </div>
      </div>
      <Footer className="container-pef "></Footer>
    </>
  );
};

const maptimeManagementProps = (state) => {
  return {
    objectivesinglePef: state.singlePef,
    textColorChange: state.textColorChange,
  };
};
export default connect(maptimeManagementProps)(ObjectiveDetails);
