import React from "react";
import Timemanagement from "./Timemanagement";
import Syllabus from "./Syllabus";
import Objective from "./Objective";
import Constructed from "./Constructed";

import { connect } from "react-redux";

const Appliedskill = ({ singlePef }) => {
  const attributes = singlePef.singlePef.attributes;

  const timeData = attributes[0];
  const constructedData = attributes[1];
  const syllabusData = attributes[2];
  const objectiveData = attributes[3];

  return (
    <div className="appliedskill-wrapper">
      <div className="appliedskill-wrapper container-pef ">
        <h2 className="feedBack">Review your feedback by</h2>
        <div className=" gridcontainer">
          <Timemanagement content={timeData} />
          <Syllabus content={syllabusData} />
          <Objective content={objectiveData} />
          <Constructed content={constructedData} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    singlePef: state.singlePef,
  };
};
export default connect(mapStateToProps)(Appliedskill);
