import React from "react";
import { connect } from "react-redux";
import {
  Anchor,
  IconArrowRight24,
  IconArrowLeft24,
  Footer,
} from "acca-design-system";

import PefSideBar from "../sidebar/PefSidebar";
import DashBoardNav from "../DashBoardNav";
import Dashboardpagetitle from "../Dashboardpagetitle";
import SyllabusData from "./SyllabusData";

const SyllabusDetails = (props) => {
  const sallybusData = props.pef.singlePef.attributes[2];
  const pageTitle = sallybusData.displayName;
  const pagedesc = sallybusData.displayDesc;

  return (
    <>
      <div className="column-layoutsplit detailpage-wrapper">
        <div className="">
          {/* onchangeclass={onchangeclassHandler} */}
          <PefSideBar />
        </div>

        <div className="dashboard-header-wrapper">
          <div className="content-wrapper">
            <DashBoardNav navBg={props.textColorChange} />
            <div className=" container-pef ">
              <div className="content-wrapper innerpage-title">
                <Dashboardpagetitle title={pageTitle} desc={pagedesc} />
              </div>
            </div>
          </div>
        </div>
        <SyllabusData sData={sallybusData.values} />
        <div className="bttn-links container-pef ">
          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/time-management"
          >
            <IconArrowLeft24 /> Time management
          </Anchor>
          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/objective-test-skills"
          >
            Objective test skills <IconArrowRight24 />
          </Anchor>
        </div>
      </div>
      <Footer className="container-pef "></Footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pef: state.singlePef,
    textColorChange: state.textColorChange,
  };
};

export default connect(mapStateToProps)(SyllabusDetails);
