export const baseUrl = process.env.REACT_APP_API_BASE_URL;
// console.log(baseUrl);
export default {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  clientId: process.env.REACT_APP_API_CLIENT_ID,
  endpoints: {
    templates: {
      landingPage: `${baseUrl}/public/ui/templates?channel=pef-portal&page=landing`,
      dashboard: `${baseUrl}/public/ui/templates?channel=pef-portal&page=dashboard`,
    },
    user: {
      pefStatus: (accaId) => `${baseUrl}/customers/${accaId}/pef/status`,
      pef: (accaId) => `${baseUrl}/customers/${accaId}/pef`,
      singlePefDetails: (accaId, pefId) =>
        `${baseUrl}/customers/${accaId}/pef/${pefId}`,
    },
  },
};
