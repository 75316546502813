import React from "react";
import { IconArrowRight24 } from "@acca-digital/react-icons";
import { Anchor } from "acca-design-system";

import objectiveIcon from "../../assets/icons/objectiveIcon.svg";
const Objective = (props) => {
  const oData = props.content.values;
  const { displayName, displayDesc } = props.content;
  const ObjectiveResult = oData.map((oData) => {
    const { code, displayName, mark } = oData;
    return (
      <div className="feedback-column" key={code}>
        <ul>
          <li>{displayName}</li>
          <li>{mark}%</li>
        </ul>
      </div>
    );
  });

  return (
    <div className="grid-item">
      <div className="icons-list">
        <img src={objectiveIcon} alt="" />
      </div>
      <h2> {displayName}</h2>
      <p>{displayDesc}</p>
      <p className="marks u-text-right">Marks</p>
      {ObjectiveResult}

      <Anchor
        className="u-padding-top-2"
        onClick={function noRefCheck() {}}
        to="/objective-test-skills"
      >
        View detailed results <IconArrowRight24 />
      </Anchor>
    </div>
  );
};

export default Objective;
