import React from "react";
import { IconArrowRight24 } from "@acca-digital/react-icons";
import TimeIconimg from "../../assets/icons/TimeIcon.svg";
import { Anchor } from "acca-design-system";

const Timemanagement = (props) => {
  // function Timemanagement(props) {

  const tData = props.content.values;
  const { displayName, displayDesc } = props.content;

  // console.log(tData);
  const timeData = tData.map((tData) => {
    const { code, displayName, mark } = tData;

    return (
      <div className="feedback-column" key={code}>
        <ul>
          <li>{displayName}</li>
          <li>{mark}%</li>
        </ul>
      </div>
    );
  });

  return (
    <>
      <div className="grid-item">
        <div className="icons-list">
          <img src={TimeIconimg} alt="" />
        </div>
        <h2> {displayName}</h2>
        <p>{displayDesc}</p>
        <p className="marks u-text-right">Marks</p>
        {timeData}
        {/* <Link to="/time-management">
          <LinkListItem text="View detailed results" icon={listIcon} />
        </Link> */}
        <Anchor
          className="u-padding-top-2"
          onClick={function noRefCheck() {}}
          to="/time-management"
        >
          View detailed results <IconArrowRight24 />
        </Anchor>
        {/* <Anchor
          className="u-padding-top-2 anchor"
          to="/time-management"
          children="View detailed results "
          icon="IconArrowRight24"
        /> */}
      </div>
    </>
  );
};

export default Timemanagement;
