import React from "react";
import { IconArrowRight24 } from "@acca-digital/react-icons";
import { Anchor } from "acca-design-system";

import syllabusIconImg from "../../assets/icons/syllabusIcon.svg";
const Syllabus = (props) => {
  const sData = props.content.values;
  const { displayName, displayDesc } = props.content;

  const sylabbusList = sData.map((sData) => {
    const { code, displayName, mark } = sData;

    return (
      <div className="feedback-column" key={code}>
        <ul>
          <li>{displayName}</li>
          <li>{mark}%</li>
        </ul>
      </div>
    );
  });

  return (
    <>
      <div className="grid-item">
        <div className="icons-list">
          <img src={syllabusIconImg} alt="" />
        </div>
        <h2> {displayName}</h2>
        <p>{displayDesc}</p>
        <p className="marks u-text-right">Marks</p>
        {sylabbusList}

        <Anchor
          className="u-padding-top-2"
          onClick={function noRefCheck() {}}
          to="/syllabus"
        >
          View detailed results <IconArrowRight24 />
        </Anchor>
      </div>
    </>
  );
};

export default Syllabus;
