import React from "react";
import {
  Anchor,
  IconArrowRight24,
  IconArrowLeft24,
  Footer,
} from "acca-design-system";
import { connect } from "react-redux";
import PefSideBar from "../sidebar/PefSidebar";
import DashBoardNav from "../DashBoardNav";
import Dashboardpagetitle from "../Dashboardpagetitle";
import TimeManagemenTmData from "./TimeManagementData";

// import { textColorChange} from "./../../reducers/TextColor";

const TimeManagementDetails = (props) => {
  const timeManagementList = props.singlePef.singlePef.attributes[0];
  const timeTitle = timeManagementList.displayName;
  const timeDesc = timeManagementList.displayDesc;
  // const exam = timeManagementList.displayDesc;

  return (
    <>
      <div className="column-layoutsplit detailpage-wrapper">
        <div className="">
          <PefSideBar />
        </div>

        <div className="dashboard-header-wrapper">
          <div className="content-wrapper">
            <DashBoardNav navBg={props.textColorChange} />
            <div className=" container-pef ">
              <div className="content-wrapper innerpage-title">
                <Dashboardpagetitle title={timeTitle} desc={timeDesc} />
              </div>
            </div>
          </div>
        </div>

        <TimeManagemenTmData TmData={timeManagementList.values} />
        <div className="bttn-links container-pef ">
          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/dashboard"
          >
            <IconArrowLeft24 /> Performance dashboard
          </Anchor>

          <Anchor
            className="u-font-bold"
            onClick={function noRefCheck() {}}
            to="/syllabus"
          >
            Syllabus <IconArrowRight24 />
          </Anchor>
        </div>
      </div>
      <Footer className="container-pef "></Footer>
    </>
  );
};

const maptimeManagementProps = (state) => {
  return {
    singlePef: state.singlePef,
    textColorChange: state.textColorChange,
  };
};
export default connect(maptimeManagementProps)(TimeManagementDetails);
