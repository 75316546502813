import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const Dashboardpagetitle = (props) => {
  const pageDisplay = props.title;
  const pageDesc = props.desc;
  const location = useLocation();
  let disclaimer = "";
  if (location.pathname == "/time-management") {
    disclaimer =
      "Please note that the analysis of your time spent is based on the time originally allocated to you at the start of your exam. We therefore recommend that you exercise caution when relying on this area of feedback if you experienced any issues during the exam.";
  }
  return (
    <div>
      <h1 className="subTitle h800 u-black u-font-bold u-padding-bottom-2 u-overline-caption">
        {pageDisplay}
      </h1>
      <h2 className="h400 u-black u-font-bold u-padding-bottom-2">
        {" "}
        {props.dashboardHeading}
      </h2>
      <p className="subTitle disclaimer h700 u-black">
        {pageDesc} <br />
        {disclaimer}
      </p>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dashboardHeading: state.singlePef.singlePef.examSubjectNameDisplay,
  };
};
export default connect(mapStateToProps)(Dashboardpagetitle);
